import axios from 'axios'
import { handleRequest } from './handler'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import Router from 'next/router'
import API_MANAGER from '../Api'

axios.defaults.headers.post['Content-Type'] = 'application/json'

let HELPERS = {
  getCookie: (name) => {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  },

  request: (config) => {
    config.headers = config.headers ? config.headers : {}
    const csrfTokenEl = document.querySelector('[name=csrfmiddlewaretoken]')
    const csrfToken =
      HELPERS.getCookie('csrftoken') || (csrfTokenEl && csrfTokenEl.value)
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken
    }
    return axios
      .request(config)
      .then((response) => {
        if (response.data.message == 'Token not valid') {
          deleteCookie('access_token')
          deleteCookie('refresh_token')
          Router.push('/login')
        } else {
          return response
        }
      })
      .catch((err) => {
        handleRequest(err)
        throw err
      })
  },

  secureRequest: async (config) => {
    config.headers = config.headers ? config.headers : {}
    const token = getCookie('access_token')

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return HELPERS.request(config)
  },

  convertToFormData: (obj, rootName, ignoreList) => {
    var formData = new FormData()
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || ''
        if (data instanceof File) {
          formData.append(root, data)
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + '[' + i + ']')
          }
        } else if (typeof data === 'object' && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === '') {
                appendFormData(data[key], key)
              } else {
                appendFormData(data[key], root + '.' + key)
              }
            }
          }
        } else {
          if (data !== null && typeof data !== 'undefined') {
            formData.append(root, data)
          }
        }
      }
    }
    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root
        })
      )
    }
    appendFormData(obj, rootName)
    return formData
  },
  numberWithCommas: (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
}

export default HELPERS
